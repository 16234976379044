import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ContentContainer } from "./layouts";

import "./assets/scss/style.scss";

import Header from "./components/header";
import Footer from "./components/footer";
import About from "./pages/about";
import Home from "./pages/home";
import Photography from "./pages/photography";
import SingleVideoPost from "./pages/videoSinglePost";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className={`p-relative error-page`}>
          <Header />
          <ContentContainer>
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/photos" element={<Photography />} />
              <Route path="/:slug" element={<SingleVideoPost />} />

              <Route path="/videos" element={<Home />} />
              <Route path="/Film" element={<Photography />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </ContentContainer>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
