export const Colors = {
  $primaryDarkColor: "#e69100",
  $primaryColor: "#FFA915",
  $primaryMediumColor: "#ffc766",
  $primaryLightColor: "#ffda99",
  $primarySuperLightColor: "#ffeccc",
  $tagColor: "#7F85A8",

  $colorGrey: "#878686",
  $colorGreyMedium: "#BDBABA",
  $colorGreyLight: "#F0F2F2",
  $colorGreyStandart: "#dddddd",

  $colorPurple: "#3B4A99",
  $colorPurpleMedium: "#7282D6",
  $colorPurpleLight: "#BDC7FF",
  $colorPurpleExtraLight: "#f1f6f7",

  $colorGreen: "#3B6F78",
  $colorGreenMedium: "#6EA5AE",
  $colorGreenLight: "#A5E1EB",

  $colorOrange: "#D1735E",
  $colorOrangeMedium: "#E79684",
  $colorOrangeLight: "#FFC4B7",

  $colorCyan: "#07A1E2",
  $colorCyanMedium: "#74CBF0",
  $colorCyanLight: "#A8E5FF",

  $colorYellow: "#E8C422",
  $colorYellowMedium: "#F2D864",
  $colorYellowLight: "#FAEBA6",

  $colorError: "#FF0000",
  // Vertical Sizes

  $sizeTiny: "20px",
  $sizeSmall: "40px",
  $sizeMedium: "60px",
  $sizeBig: "80px",
  $sizeLarge: "100px",
  $sizeExtraLarge: "140px",
};

export const Formats = {
  $containerFloatHalf: "calc((100vw - 1310px) / 2)",
  $containerFloatHalfLarge: "calc((100vw - 1170px) / 2)",
  $containerFloatHalfMid: "calc((100vw - 960px) / 2)",
  $containerFloatHalfSmall: "calc((100vw - 720px) / 2)",
};

export const Fonts = {
  $regular: "Kanit-Regular",
  $bold: "Kanit-Bold",
  $medium: "Kanit-Medium",
  $thin: "Kanit-Thin",
  $light: "Kanit-Light",
  $extraLight: "Kanit-ExtraLight",
};

export const Breakpoints = {
  $extraSmall: "567px",
  $small: "768px",
  $medium: "992px",
  $large: "1200px",
};

export const Transitions = {
  $superSmooth: "cubic-bezier(0.25, 1, 0.5, 1)",
  $linearSmooth: "cubic-bezier(0, 0.55, 0.45, 1)",
};
