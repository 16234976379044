import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import contentfulClient from "../../Contentful";
import { Container } from "../../layouts";
import {
  SinglePostLayout,
  VideoTemplate,
  VideoInfoLayout,
  InfoField,
  InfoLabel,
} from "./layouts";

const VideoSinglePost = () => {
  const { slug } = useParams();
  const [singleVideoData, setSingleVideoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const response = await contentfulClient.getEntries({
          "fields.slug": slug,
          content_type: "testVideosExample",
          limit: 1,
        });
        if (response.items.length > 0) {
          setSingleVideoData(response.items[0].fields);
        } else {
          setError(new Error("Post not found"));
        }
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (isLoading) {
    return (
      <Container>
        <SinglePostLayout></SinglePostLayout>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <SinglePostLayout>Video not found!</SinglePostLayout>
      </Container>
    );
  }

  if (singleVideoData) {
    const youtubeEmbedUrl = `https://www.youtube.com/embed/${singleVideoData.youtubeId}`;
    return (
      <Container>
        <SinglePostLayout>
          <div className="w-full">
            <VideoTemplate
              src={youtubeEmbedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <VideoInfoLayout>
            <InfoField>
              <InfoLabel>Title:</InfoLabel>
              <InfoLabel>{singleVideoData.title}</InfoLabel>
            </InfoField>
            <InfoField>
              <InfoLabel>Artist:</InfoLabel>
              <InfoLabel>{singleVideoData.artist}</InfoLabel>
            </InfoField>
            <InfoField>
              <InfoLabel>D.O.P:</InfoLabel>
              <InfoLabel>{singleVideoData.director}</InfoLabel>
            </InfoField>
            <InfoField>
              <InfoLabel>Production Company:</InfoLabel>
              <InfoLabel>{singleVideoData.productionCompany}</InfoLabel>
            </InfoField>
          </VideoInfoLayout>
        </SinglePostLayout>
      </Container>
    );
  }

  return <Container>Post not found.</Container>;
};

export default VideoSinglePost;
