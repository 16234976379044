import styled from "@emotion/styled";
import { Colors } from "../assets/variables/variables";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0px 0px 0px;
  margin-bottom: 10px;
`;

export const BoxTest = styled.div`
  width: 360px;
  height: 200px;
  background-color: #fff;

  margin-bottom: 25px;
`;

export const LogoContainer = styled.div`
  position: relative;
  width: 225px;
  height: 50px;

  @media (max-width: 766px) {
    width: 225px;
    height: 50px;
  }
`;

export const BigTag = styled.span`
  position: relative;
  font-size: 20px;
  line-height: 1.1;
  color: ${Colors.$tagColor};
`;

export const Tag = styled.span`
  position: relative;
  font-size: 16px;
  line-height: 1.1;
  color: ${Colors.$tagColor};
`;

export const BigTitle = styled.h1`
  font-size: 54px;
  line-height: 1.3;
`;

export const Title = styled.h2`
  font-size: 32px;
`;

export const SubTitle = styled.h2`
  font-size: 25px;
`;

export const Paragraph = styled.p`
  font-size: 18px;
`;

export const ProductTitle = styled.span`
  display: inline-block;
  font-size: 20px;
  color: #7c7c7c;
`;

export const Quote = styled.span`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  font-style: italic;
  line-height: 1;

  &:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    left: 0;
    top: 0;
    background: ${Colors.$primaryColor};
  }
`;

export const TitleExpresion = styled.h3`
  position: relative;
  padding-left: 50px;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${Colors.$primaryColor};

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 2px;
    border-radius: 3px;
    background-color: ${Colors.$primaryColor};
    transform: translateY(-50%);
  }
`;

export const CategoriesLinkEffect = styled.p`
  padding: 5px;
  &:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${Colors.$primaryColor};
    transition: 0.2s;
  }

  &:hover {
    &:before {
      bottom: 3px;
    }
  }
`;

export const SingleProductImage = styled.div`
  position: relative;
  display: flex;
  height: 450px;
  text-align: center;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 100%;
    background: ${Colors.$primarySuperLightColor};
    border-radius: 0 1000px 1000px 0;
    z-index: -1;
  }
`;

export const MiniProductFeature = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  border: 1px solid black;
  padding: 5px;
  margin-right: 15px;
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    background: ${Colors.$primaryLightColor};
  }
`;

export const SubmitButton = styled.button`
  font-size: 15px;
  cursor: pointer;
  background-color: #384aeb;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 15px 40px 15px 40px;

  &:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid ${Colors.$colorGreyStandart};
  }
`;

//---------------------------
export const HamburgerIcon = styled.button`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const CloseIcon = styled.button`
  font-size: 24px;
  cursor: pointer;
  &:before {
    content: "×";
  }

  @media (max-width: 768px) {
  }
`;

export const BeforeMobileMenu = styled.div`
  display: none;

  @media (max-width: 766px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const MobileMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};

  flex-direction: column;
  justify-content: flex-start;

  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
`;

export const DesktopMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 766px) {
    display: none;
  }
`;

export const MobileNavIcon = styled.div`
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin-right: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const PostContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  width: 33.3333%;
  box-sizing: border-box;

  overflow: hidden;
`;

export const FeatureImage = styled.div`
  position: relative;
  height: 225px;
  width: 100%;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-bottom: 30px;
  border: 1px solid #000;

  background-color: #2d9596;

  &:hover > div {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const ComingSoonLayout = styled.div`
  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  @media (max-width: 766px) {
    min-height: 0px;
  }
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  @media (max-width: 766px) {
    min-height: 0px;
  }
`;
