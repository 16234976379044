import React from "react";

import { Container } from "../../layouts";
import { ContentLayout } from "../../layouts/layouts";
import { ComingSoonLayout } from "../../layouts/layouts";

import Story from "../photo-story";
const PhotoPosts = () => {
  return (
    <Container>
      <ContentLayout>
        <ComingSoonLayout>
          <h1>COMING SOON...</h1>
          {/* 
          <div className="photography-page bg-black ">
            <Story />
          </div>
          */}
        </ComingSoonLayout>
      </ContentLayout>
    </Container>
  );
};

export default PhotoPosts;
