import kushtrimTernavaLogo from "../../assets/images/logoKT3.png";
import { LogoContainer } from "../../layouts/layouts";
import Image from "../image";

const Logo = ({ className }) => {
  return (
    <LogoContainer className={className}>
      <a href="/">
        <Image
          source={kushtrimTernavaLogo}
          classList={"w-full h-full object-contain"}
          alt="logo"
        />
      </a>
    </LogoContainer>
  );
};

export default Logo;
