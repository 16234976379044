import React from "react";
import "../../assets/scss/style.scss";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <div>
      <div>
        <NavLink
          to="/"
          className={"navlink text-decoration-none color-black mr-20 "}
        >
          FEATURED
        </NavLink>
        <NavLink
          to="/photos"
          className={"navlink text-decoration-none color-black mr-20"}
        >
          PHOTOGRAPHY
        </NavLink>
        <NavLink
          to="/"
          className={"navlink text-decoration-none color-black mr-20"}
        >
          MUSIC VIDEO
        </NavLink>

        {/* 
        <NavLink
          to="/Narrative"
          className={"navlink text-decoration-none color-black mr-20"}
        >
          NARRATIVE
        </NavLink>
        */}
        <NavLink
          to="/about"
          className={"navlink text-decoration-none color-black mr-20"}
        >
          ABOUT
        </NavLink>
      </div>
    </div>
  );
};

export default Navigation;
