import styled from "@emotion/styled";

export const AboutLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageLayout = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 400px;
    height: 400px;

    @media (max-width: 766px) {
      width: 200px;
      height: 200px;
    }
  }
`;

export const CollaboratorsText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AboutDescription = styled.p`
  padding: 20px;
  font-size: 18px;
  text-align: center;
  @media (max-width: 766px) {
    padding: 30px;
    font-size: 15px;
  }
`;
