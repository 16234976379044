import imaginefilms from "../../assets/images/imagineLogo.jpg";
import onimaLogo from "../../assets/images/onimLogo.jpg";
import deljaZeze from "../../assets/images/logoDeljaZeze-01.png";

export const collaboratorData = [
  {
    id: "collaborator3",
    img: deljaZeze,
    collaboratorUrl: "https://www.example.com/collaborator2",
  },
  {
    id: "collaborator1",
    img: imaginefilms,
    collaboratorUrl: "https://www.facebook.com/Imaginefilmsllc/",
  },
  {
    id: "collaborator2",
    img: onimaLogo,
    collaboratorUrl: "https://fati.tv/",
  },
];
