import React from "react";
import { PostContainer, FeatureImage } from "../../layouts/layouts";

const CollaboratorPost = ({ size, img, collaboratorUrl }) => {
  return (
    <PostContainer className={size}>
      <a href={collaboratorUrl}>
        <FeatureImage>
          <img
            src={img}
            alt="collaborator"
            className="w-full h-full object-cover"
          />
        </FeatureImage>
      </a>
    </PostContainer>
  );
};

export default CollaboratorPost;
