import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Container } from "../../layouts";
import Navigation from "../navigation";
import Logo from "../../components/logo";
import {
  HeaderContainer,
  MobileMenu,
  DesktopMenu,
  MobileNavIcon,
  CloseIcon,
  HamburgerIcon,
  LogoContainer,
  BeforeMobileMenu,
} from "../../layouts/layouts";
import { Helmet } from "react-helmet";

import menu from "../../assets/icons/menu-04.png";
import closeM from "../../assets/icons/closeIcon.png";

export const MenuIcon = () => {
  return <img src={menu} alt="menu Icon" width="30px" height="30px" />;
};

export const CloseMenu = () => {
  return <img src={closeM} alt="menu Icon" width="20px" height="20px" />;
};

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const pageTitle = "Kushtrim Ternava";
  const pageDescription = "Welcome to high-quality professional work.";

  const ogImageUrl =
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.facebook.com%2Fprofile.php?id=650127135&psig=AOvVaw2tB6dw2eEgbnQc1L44PCrM&ust=1687890944794000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCID_lvPJ4f8CFQAAAAAdAAAAABAE";

  return (
    <Container>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="http://yourwebsite.com/page-url" />

        {/* Open Graph */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:url" content="http://yourwebsite.com/page-url" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={ogImageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <HeaderContainer>
        <BeforeMobileMenu>
          <div className="d-flex">
            <MobileNavIcon onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <CloseMenu /> : <MenuIcon />}
            </MobileNavIcon>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </div>
          <MobileMenu isOpen={isMobileMenuOpen}>
            <div className="d-flex flex-column ">
              <NavLink
                to="/"
                className={"navlink text-decoration-none color-black mr-20 "}
              >
                FEATURED
              </NavLink>
              <NavLink
                to="/Photos"
                className={"navlink text-decoration-none color-black mr-20"}
              >
                PHOTOGRAPHY
              </NavLink>
              <NavLink
                to="/Videos"
                className={"navlink text-decoration-none color-black mr-20"}
              >
                MUSIC VIDEO
              </NavLink>
              <NavLink
                to="/Film"
                className={"navlink text-decoration-none color-black mr-20 "}
              >
                FILM
              </NavLink>
              {/* 
              <NavLink
                to="/Narrative"
                className={"navlink text-decoration-none color-black mr-20"}
              >
                NARRATIVE
              </NavLink>*/}
              <NavLink
                to="/About"
                className={"navlink text-decoration-none color-black mr-20"}
              >
                ABOUT
              </NavLink>
            </div>
          </MobileMenu>
        </BeforeMobileMenu>
        <DesktopMenu>
          <div>
            <NavLink
              to="/"
              className={"navlink text-decoration-none color-black mr-20 "}
            >
              FEATURED
            </NavLink>
            <NavLink
              to="/Photos"
              className={"navlink text-decoration-none color-black mr-20"}
            >
              PHOTOGRAPHY
            </NavLink>
            <NavLink
              to="/Videos"
              className={"navlink text-decoration-none color-black mr-20"}
            >
              MUSIC VIDEO
            </NavLink>
            <NavLink
              to="/Film"
              className={"navlink text-decoration-none color-black mr-20 "}
            >
              FILM
            </NavLink>
            {/* 
            <NavLink
              to="/Narrative"
              className={"navlink text-decoration-none color-black mr-20"}
            >
              NARRATIVE
            </NavLink>
            */}
            <NavLink
              to="/About"
              className={"navlink text-decoration-none color-black mr-20"}
            >
              ABOUT
            </NavLink>
          </div>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </DesktopMenu>
      </HeaderContainer>
    </Container>
  );
};

export default Header;
