import React from "react";
import { Link } from "react-router-dom";

import { AboutVideoLayout, VideoData, Badge } from "./layouts";

import { PostContainer, FeatureImage } from "../../layouts/layouts";

const VideoPost = ({
  id,
  size,
  featureImg,
  videoTitle,
  videoArtist,
  badge,
  slug,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <PostContainer
      className={size}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to={`/${slug}`}>
        <FeatureImage isHovered={isHovered}>
          {badge && <Badge src={badge} alt="badge" />}
          <img
            src={featureImg}
            alt="product"
            className="w-full h-full object-cover"
          />
          {isHovered && (
            <AboutVideoLayout>
              <VideoData>
                <div className="mb-10">{videoTitle}</div>
                <div className="mb-10"> {videoArtist}</div>
              </VideoData>
            </AboutVideoLayout>
          )}
        </FeatureImage>
      </Link>
    </PostContainer>
  );
};

export default VideoPost;
