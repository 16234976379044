import React from "react";
import { Flex } from "../../layouts";
import Image from "../image";
import image from "../../assets/images/AboutPhoto.jpg";
import { ContentLayout } from "../../layouts/layouts";
import {
  AboutLayout,
  ImageLayout,
  AboutDescription,
  CollaboratorsText,
} from "../about-details/layouts";

const AboutDetails = () => {
  return (
    <ContentLayout>
      <Flex>
        <AboutLayout>
          <ImageLayout>
            <Image source={image} alt={"about-details"} />
          </ImageLayout>
          <div className="w-full text-center">
            <AboutDescription>
              Kushtrim Tërnava was born on 17 August, 1981. From an early
              childhood he preferred learning through his personal experience
              rather than theory. Kushtrim went through the path of becoming a
              director of photography through direct experience. Therefore, from
              the very beginning, he was immersed in the production process from
              the inside.
            </AboutDescription>
          </div>
          <CollaboratorsText>
            <AboutDescription>Collaborators:</AboutDescription>
          </CollaboratorsText>
        </AboutLayout>
      </Flex>
    </ContentLayout>
  );
};

export default AboutDetails;
