import styled from "@emotion/styled";

export const SinglePostLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const VideoTemplate = styled.iframe`
  width: 100%;
  height: 600px;

  @media (max-width: 766px) {
    height: 400px;
  }
`;
export const VideoInfoLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;

  @media (max-width: 766px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const InfoField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px;
`;

export const InfoLabel = styled.h2`
  font-size: 20px;
  font-weight: 400;
  padding-right: 10px;
  padding-bottom: 10px;
`;
