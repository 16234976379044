import React, { useState, useEffect } from "react";
import { createClient } from "contentful";

const client = createClient({
  space: "hx2y435lata7",
  accessToken: "QgRb6EgyUm8sxCsTHLRztPFiz9WkFhvYfuXbJFEQtuk",
});

const Story = () => {
  const [stories, setStories] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .getEntries({ content_type: "photoStory" })
      .then((response) => {
        setStories(response.items);
        const posts = response.items.flatMap((item) =>
          item.fields.photos.map((photo) => ({
            storyTitle: item.fields.title,
            ...photo.fields,
          }))
        );
        setAllPosts(posts);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const handleStorySelect = (storyTitle) => {
    // Find the index of the first post in the selected story
    const storyStartIndex = allPosts.findIndex(
      (post) => post.storyTitle === storyTitle
    );
    if (storyStartIndex >= 0) {
      setCurrentPostIndex(storyStartIndex);
    }
  };

  const showPrevPost = () => {
    setCurrentPostIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const showNextPost = () => {
    setCurrentPostIndex((prevIndex) =>
      prevIndex < allPosts.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const currentPost = allPosts[currentPostIndex];

  return (
    <div>
      {/* 
        <div className="photography-page">
          <div className="story-sidebar">
            <p className="mb-10">Gallery: </p>
            {stories.map((story, index) => (
              <div
                className="storyTitle"
                key={index}
                onClick={() => handleStorySelect(story.fields.title)}
              >
                {story.fields.title}
              </div>
            ))}
          </div>
          <div className="story-content">
            {currentPost ? (
              <div>
                <h2 className="color-white">{currentPost.storyTitle}</h2>
                <img
                  src={currentPost.img.fields.file.url}
                  alt={currentPost.description}
                  className="PostImgContainer"
                />
                <p className="color-white">{currentPost.description}</p>
                <button
                  onClick={showPrevPost}
                  disabled={currentPostIndex === 0}
                >
                  &lt;&lt;
                </button>
                <button
                  onClick={showNextPost}
                  disabled={currentPostIndex === allPosts.length - 1}
                >
                  &gt;&gt;
                </button>
              </div>
            ) : (
              <div className="color-white">
                Please select a story to view its content.
              </div>
            )}
          </div>
        </div>*/}
    </div>
  );
};

export default Story;
