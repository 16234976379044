import React from "react";
import PhotoPosts from "../components/photo-posts";
const Photography = () => {
  return (
    <div>
      <PhotoPosts />
    </div>
  );
};

export default Photography;
