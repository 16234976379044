import React, { useState, useEffect } from "react";
import contentfulClient from "../../Contentful";

import { Flex, Row, Container } from "../../layouts";
import { ContentLayout } from "../../layouts/layouts";
import VideoPost from "../video-post";

import { PostContainer } from "../../layouts/layouts";

const Videos = () => {
  const [videoPostsData, setVideoPostsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const response = await contentfulClient.getEntries({
          content_type: "testVideosExample",
        });
        setVideoPostsData(response.items);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (isLoading) {
    return (
      <Container>
        <ContentLayout>
          <Flex className="flex-wrap">
            <PostContainer></PostContainer>
          </Flex>
        </ContentLayout>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ContentLayout>
          <Flex className="flex-wrap">
            <PostContainer>Video not found!</PostContainer>
          </Flex>
        </ContentLayout>
      </Container>
    );
  }

  return (
    <Container>
      <ContentLayout>
        <Flex className="flex-wrap ">
          <Row>
            {videoPostsData.map((post) => {
              return (
                <VideoPost
                  key={post.sys.id}
                  slug={post.fields.slug}
                  size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  featureImg={post.fields.image.fields.file.url}
                  videoTitle={post.fields.title}
                  videoArtist={post.fields.artist}
                  video={post.fields.youtubeId}
                  prodCompany={post.fields.productionCompany}
                />
              );
            })}
          </Row>
        </Flex>
      </ContentLayout>
    </Container>
  );
};

export default Videos;
