import React from "react";

import { Flex, Row, Container } from "../../layouts";
import { ContentLayout } from "../../layouts/layouts";
import CollaboratorPost from "../collaborator-post";

import { collaboratorData } from "../collaborator-data/index";

const Collaborators = () => {
  return (
    <Container>
      <ContentLayout>
        <Flex className="flex-wrap ">
          <Row>
            {collaboratorData.map((collaborator) => (
              <CollaboratorPost
                key={collaborator.id}
                size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                img={collaborator.img}
                collaboratorUrl={collaborator.collaboratorUrl}
              />
            ))}
          </Row>
        </Flex>
      </ContentLayout>
    </Container>
  );
};

export default Collaborators;
