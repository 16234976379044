import React from "react";
import Logo from "../logo";
import { Link } from "react-router-dom";
import { Container, Flex } from "../../layouts";
import {
  FooterLayout,
  FooterSubLayout,
  FacebookIcon,
  InstagramIcon,
  IconLayout,
  InfoLayout,
  SocialIconsLayout,
  ContactLayout,
  InfoBottomLayout,
} from "./layouts";

import location from "../../assets/icons/location.png";
import mail from "../../assets/icons/mail.png";
import telephone from "../../assets/icons/telephone.png";

export const LocationIcon = () => {
  return <img src={location} alt="Location Icon" width="35px" height="35px" />;
};

export const MailIcon = () => {
  return <img src={mail} alt="Email Icon" width="35px" height="35px" />;
};

export const PhoneIcon = () => {
  return <img src={telephone} alt="Phone Icon" width="35px" height="35px" />;
};

const Footer = () => {
  return (
    <Container>
      <FooterLayout>
        <FooterSubLayout>
          <h5 className="pb-20">GET IN TOUCH:</h5>
          <InfoLayout>
            <SocialIconsLayout className="flex pr-15">
              <Flex>
                <a
                  href="https://www.facebook.com/profile.php?id=650127135"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-10"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/kushtrim_ternava/"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-10"
                >
                  <InstagramIcon />
                </a>
              </Flex>
            </SocialIconsLayout>

            <ContactLayout className="flex ">
              <div className="pb-10 text-decoration-none color-black">
                <IconLayout>
                  <LocationIcon></LocationIcon> <p>Pristina, Kosova</p>
                </IconLayout>
              </div>

              <div className="pb-10 text-decoration-none color-black">
                <IconLayout>
                  <MailIcon></MailIcon>
                  <p className="d-flex flex-wrap">kushtrimternava@gmail.com</p>
                </IconLayout>
              </div>

              <div className="pb-10 text-decoration-none color-black ">
                <IconLayout>
                  <PhoneIcon></PhoneIcon>
                  <p className="d-flex flex-wrap">383 49 225 400</p>
                </IconLayout>
              </div>
            </ContactLayout>
          </InfoLayout>
          <InfoBottomLayout className="py-20">
            <p
              to="#"
              className="d-inline-block text-decoration-none color-black"
            >
              ©2024
            </p>
            <p
              to="#"
              className="d-inline-block text-decoration-none color-black"
            >
              Created by{" "}
              <a href="https://linktr.ee/muhametternava">Muhamet Ternava</a>
            </p>
          </InfoBottomLayout>
        </FooterSubLayout>
      </FooterLayout>
    </Container>
  );
};

export default Footer;
