import { Container } from "../layouts";
import Aboutusdetails from "../components/about-details";
import Collaborators from "../components/collaborator-posts";

const About = () => {
  return (
    <Container>
      <Aboutusdetails />
      <Collaborators />
    </Container>
  );
};

export default About;
