import styled from "@emotion/styled";

export const AboutVideoLayout = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  padding: 10px 0;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.3s ease;
`;

export const VideoData = styled.div`
  margin-top: 150px;
  margin-left: 35px;
  color: black;

  font-family: "Maven Pro";

  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
`;

export const Badge = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f05941;
`;
